// customize some Bootstrap variables
//$primary: darken(#428bca, 20%);

// the ~ allows you to reference things in node_modules
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import '~bootstrap-sass/assets/stylesheets/bootstrap';

@import '~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';


@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';