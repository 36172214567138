.label-as-badge {
    border-radius: 1em;
}

@media print {
    a[href]:after {
        content: none !important;
    }
}

body {
    #background-color: lightgray;
}